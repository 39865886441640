import React, { useEffect, useState } from 'react';
import { getMethod } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { Toaster } from 'react-hot-toast';
import {BsFillPlayCircleFill} from 'react-icons/bs';
import ReactPlayer from 'react-player';
import NotFound from '../../PanelComponent/NotFound'
import { useHistory } from 'react-router-dom';
const ViewApplication = (props) => {
    const history = useHistory()
    const [loader, setLoader] = useState(true);
    const [applicationData, setApplication] = useState([]);
    const [isError, setIsError] = useState(false);
    const getApplicationDetails = async (id) => {
        const response = await getMethod('admin/response/' + id);
        if (response.success) {
            setApplication(response.data)
            setLoader(false)
        } else {
                setApplication([]);
                setLoader(false)
                setIsError(true)
        }
    }
    useEffect(() => {
        if (props.match.params.id) {
            getApplicationDetails(props.match.params.id)
        } else {
            setLoader(false)
            setIsError(true)
            //history.push('/user-application-list')
           // window.open('/user-application-list', '_self')
        }
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">View Application</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                 {
                                                                     isError?
                                                                        <NotFound/>
                                                                     :
                                                                     <div className="nk-tb-list nk-tb-ulist" >
                                                                     <div className='row' style={{ padding: '15px', width: '100%', margin: '0px' }}>
                                                                         {
                                                                             applicationData?.length ?
                                                                                 applicationData?.map((data, index) =>
                                                                                     <div className='col-md-12 col-12' key={index} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                         <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>{`Q${index + 1}. ${data?.question?.label}`}</h3>
                                                                                         <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: &nbsp;
                                                                                             {
                                                                                                 data?.question?.questionType === 'document' ?
                                                                                                     <a href={process.env.REACT_APP_IMG_URL + '/' + data?.answer} target='_blank' style={{ cursor: 'pointer' }}>View Document</a>
                                                                                                     : data?.question?.questionType === 'url' ?
                                                                                                         <a href={data?.answer} target='_blank' style={{ cursor: 'pointer' }}>{data?.answer}</a>
                                                                                                         : data?.question?.questionType === 'booleanType' ?
                                                                                                             data?.answer ? 'Yes' : 'No'
                                                                                                             : data?.question?.questionType === 'singleChoice' ?
                                                                                            
                                                                                                                 (data?.question?.options?.find(({ _id }) => _id === data?.answer ? _id === data?.answer : false))?.label ? (data?.question?.options?.find(({ _id }) => _id === data?.answer ? _id === data?.answer : false))?.label : 'Not Attempt.'
                                                                                                                 :
                                                                                                                 data?.question?.questionType === 'video' ?
                                                                                                                     data?.answer?
                                                                                                                     <ReactPlayer style={{padding:'15px',borderRadius:'15px',background:'#325687',marginTop:'15px'}} url={process.env.REACT_APP_IMG_URL + '/' + data?.answer} controls pip={true} light playIcon={<BsFillPlayCircleFill style={{width:'55px', height:'55px', color:'#0066f0'}}/>} progressInterval={1000} stopOnUnmount={false} playsinline={true} playing={false} />
                                                                                                                     :null
                                                                                                                     :
                                                                                                                     data?.answer}
                                                                                         </p>
                                                                                     </div>
                                                                                 )
                                                                                 : null
                                                                         }
                                                                     </div>
                                                                 </div>   
                                                                 }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default ViewApplication;