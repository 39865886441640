import React, { lazy, Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RenderLoader from "../../../PanelComponent/RenderLoader";
import { getCookies } from "../../../Utils/cookies";
import {isAuth} from '../../../middleware/isAuth'
const LoginSection = lazy(() => import('../Components/LoginSection'));
const renderLoader = () => <RenderLoader trans={false} />;
const Login = (props) => {
    const history = useHistory();
    return (
                <Suspense fallback={renderLoader()}>
                    <LoginSection />
                </Suspense>
    )
}
export default Login;