import React, { useEffect, useState } from "react";
import { Route, Switch, Router, useHistory, Redirect } from 'react-router-dom';
import Footer from "../Components/Footer/Footer";
import Sidebar from "../Components/Sidebar/Sidebar";
import Topbar from "../Components/Topbar/Topbar";
import Login from "../Pages/Auth/Login/Login";
import PrivacyPage from "../PanelPages/PrivacyPage";
import NotFound from "../PanelComponent/NotFound";
import UserApplication from '../Pages/UserApplication/UserApplication'
import { isAuth } from "../middleware/isAuth";
import ViewApplication from "../Pages/UserApplication/ViewApplication";
import { getCookies } from "../Utils/cookies";
import UserList from "../Pages/UserModule/UserList";
import UserDetails from "../Pages/UserModule/UserDetails";
import MentorApplication from "../Pages/MentorApplication/MentorApplication";
import ViewForm from "../Pages/MentorApplication/ViewForm";
const Routes = () => {
    const history = useHistory();
    const PublicRoute = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('isActive') ? JSON.parse(getCookies('isActive')) : false;
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuth?.active ? <Redirect to="/user-application-list" /> : <Component {...props} />
                }
            />
        );
    }
    const XAdminRoutes = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('isActive') ? JSON.parse(getCookies('isActive')) : { active: false, role: false };
        if (isAuth?.role === 'admin' && isAuth?.active || isAuth?.role === 'superadmin' && isAuth?.active) {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Component {...props} />
                    }
                />
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Redirect to="/" />
                    }
                />
            );
        }
    }
    const ListPrivate = [
        {
            path: '/user-application-list',
            exact: true,
            component: UserApplication,
        },
        {
            path: '/view-application',
            exact: true,
            component: ViewApplication
        },
        {
            path: '/mentor-list',
            exact: true,
            component: MentorApplication
        },
        {
            path: '/view-mentor-application/:id',
            exact: true,
            component: ViewForm
        },
        {
            path: '/view-application/:id',
            exact: true,
            component: ViewApplication
        },
        {
            path: '/user-list',
            exact: true,
            component: UserList
        },
        {
            path:'/user-details/:id',
            exact:true,
            component: UserDetails
        },
        {
            path:'/admin/:id',
            exact:true,
            component: UserDetails
        }
    ]
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <Switch>
            <PublicRoute path='/' exact component={Login} />
            <PublicRoute path='/login' exact component={Login} />
            <Route path='/privacy-policy' exact component={PrivacyPage} />
            <div className="nk-body bg-lighter npc-general has-sidebar ">
                <div className="nk-app-root">
                    <div className="nk-main">
                        <Sidebar />
                        <div className="nk-wrap">
                            <Topbar />
                            {
                                ListPrivate?.map((items, index) =>
                                    <XAdminRoutes path={items?.path} exact={items?.exact} component={items?.component} key={index} />
                                )
                            }
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <Route component={NotFound} />
            {/* <Route path='/404' component={NotFound} /> */}
        </Switch>

    );
}
export default Routes;