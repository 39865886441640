import React, { useEffect } from 'react';
import { BASE_IMG_URL } from '../Utils/Service';
import { useHistory } from 'react-router';
const NotFound = () => {
    const history = useHistory();
    useEffect(() => {
        //history.push('/404');
        window.scroll(0, 0);
    }, [])
    return (
        <div className="sideBarUL nk-content" >
            <div className="container-fluid" >
                <div className="nk-content-inner" >
                    <div className="nk-content-body" >
                        <div className="nk-block" >
                            <div className="card card-bordered" style={{border:'none'}} >
                                <div className="card-aside-wrap" >
                                    <div className="card-inner card-inner-lg">
                                        <div style={{ width: '100%' }}>
                                            <div className="nk-block">
                                                <div className="card card-bordered card-stretch" style={{border:'none'}}>
                                                    <div className='container' style={{ width: '100%' }}>
                                                        <div className='row justify-content-center'>
                                                            <div className='col-lg-12 col-12'>
                                                                <img src={'/assets/images/404.gif'} className='img-fluid' style={{ width: '100%', height: '100%' }} />
                                                            </div>
                                                            <div className='col-lg-12 col-12 text-center' style={{ backgroundColor: 'transparent' }}>
                                                                <h1 style={{ fontSize: '31px', color: 'red', fontWeight: '600' }}>
                                                                    404 Not Found
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotFound;