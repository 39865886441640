import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { dateFormatConverter } from '../../Utils/Logic';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { getMethod, putMethod } from '../../Utils/Service';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link } from 'react-router-dom';
const UserList = (props) => {
    const history = useHistory();
    const [total, setTotal] = useState(0);
    const [list, setTotalList] = useState([]);
    const [loader, setLoader] = useState(true);
    const getListAPI = async () => {
        await getMethod('admin/users').then(response => {
            if (response.success) {
                setTotal(response.data.length);
                setTotalList(response.data);
                setLoader(false)
            } else {
                    setLoader(false)
            }
        })
    };
    useEffect(() => {
        getListAPI();
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">User Application List</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {
                                                            total ?
                                                                <p>You have total {total === 1 ? total + ' user' : total + ' users'}</p>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        <div className="nk-tb-col nk-tb-col-check">
                                                                            <span className="sub-text text-center">S.No</span>
                                                                        </div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Email</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Mobile</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Name</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Domain</span></div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        list?.length ?
                                                                            list?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>
                                                                                    <div className="nk-tb-col nk-tb-col-check">
                                                                                        <span className="sub-text text-center">{index + 1}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center">{data?.email}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.mobile?data?.mobile:'Profile Not Completed!'}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.firstName + ' ' + data?.lastName}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.domain}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><Link to={'/user-details/' + data?._id}><em className="icon ni ni-view"></em><span>View User Details</span></Link></li>
                                                                                                            {/* <li><a onClick={(e) => {
                                                                                                                handleToggleAdmin(data.role, data?._id)
                                                                                                            }}><em className="icon ni ni-view"></em><span>{data?.role === 'user' ? 'Make Admin' : 'Remove Admin'}</span></a></li> */}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    list?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default UserList;
