import React, {useEffect} from "react";
import { isAuth } from "./middleware/isAuth";
import Routes from "./Routes/Routes";
function App() {
  useEffect(() => {
    isAuth();
    return () => {
    };
  }, []);
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
