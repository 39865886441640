import React from "react";
import { Link } from "react-router-dom";
import { FcList } from 'react-icons/fc';
import {FiUsers} from 'react-icons/fi';
import {FaElementor} from 'react-icons/fa';
import { MdManageAccounts } from 'react-icons/md';
const Sidebar = (props) => {
    return (
        <div className="sideBarUL nk-sidebar nk-sidebar-fixed is-dark " data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head" style={{ backgroundColor: '#fff' }}>
                <div className="nk-sidebar-brand" style={{ backgroundColor: '#fff' }}>
                    <Link to="/user-application-list" className="logo-link nk-sidebar-logo">
                        <img className="logo-light logo-img" src={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} srcSet={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} alt="logo" />
                        <img className="logo-dark logo-img" src={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} srcSet={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} alt="logo-dark" />
                    </Link>
                </div>
                <div className="nk-menu-trigger mr-n2">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                </div>
            </div>
            <div className="nk-sidebar-element">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            <li className="nk-menu-item has-sub active">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><FcList />Startup Application</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to='/user-application-list' className="nk-menu-link"><span className="nk-menu-text">List</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub active">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><FaElementor />Mentor Application</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to='/mentor-list' className="nk-menu-link"><span className="nk-menu-text">Mentor List</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub active">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><FiUsers />User List</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to='/user-list' className="nk-menu-link"><span className="nk-menu-text">User List</span></Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sidebar;