import React, { useEffect, useState } from 'react';
import { getMethod, putMethod } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { Toaster } from 'react-hot-toast';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import NotFound from '../../PanelComponent/NotFound';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory } from 'react-router-dom';
import { dateFormatConverter } from '../../Utils/Logic';
import toast from 'react-hot-toast';
const UserDetails = (props) => {
    const history = useHistory()
    const [open, setOpen] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loader, setLoader] = useState(true);
    const [applicationData, setApplication] = useState([]);
    const [isError, setIsError] = useState(false);
    const [handleUpdatePassword, setHandlePassword] = useState(false);
    const onCloseModal = (props) => {
        setOpen(false);
    }
    const handleUpdatePassowrdAdmin=async(e)=>{
        e.preventDefault();
        if (newPassword != confirmPassword) {
            toast.error('New Passowrd & Confirm Password not be same!')
        } else {
            if (newPassword?.length > 6) {
                const data = ({ password: newPassword,confirmpassword:confirmPassword})
                await putMethod(props?.match?.path === '/admin/:id'?'admin/change-password':'admin/change-password/'+props.match.params.id, data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        onCloseModal()
                        setConfirmPassword('')
                        setNewPassword('');
                        getApplicationDetails(props.match.params.id)
                    } else {
                        toast.error(response.message);
                    }
                })
            } else {
                toast.error('Password must be greater than 6 characters')
            }
        }
    }
    const handleCreateAdmin = async (e) => {
        e.preventDefault();
        if (newPassword != confirmPassword) {
            toast.error('New Passowrd & Confirm Password not be same!')
        } else {
            if (newPassword?.length > 6) {
                const data = ({ user: props.match.params.id, password: newPassword })
                await putMethod('admin/add', data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        onCloseModal()
                        setConfirmPassword('')
                        setNewPassword('');
                        getApplicationDetails(props.match.params.id)
                    } else {
                        toast.error(response.message);
                    }
                })
            } else {
                toast.error('Password must be greater than 6 characters')
            }
        }
    }

    const handleRemoveAdmin = async (id) => {
        let data = ({ user: id });
        await putMethod('admin/remove', data).then(response => {
            if (response.success) {
                toast.success(response.message);
                getApplicationDetails(id)
            } else {
                toast.error(response.message);
            }
        })

    }
    const getApplicationDetails = async (id) => {
        const response = await getMethod('admin/user/' + id);
       // console.log(response)
        if (response.success) {
            let removeSomeKey = response.data;
            delete removeSomeKey['_id'];
            delete removeSomeKey['__v'];
            delete removeSomeKey['updatedAt'];
            delete removeSomeKey['isAccept'];
            delete removeSomeKey['isAgree']
            setApplication(removeSomeKey)
            setLoader(false)
        } else {
            setApplication([]);
            setLoader(false)
            setIsError(true)
        }
    }
    useEffect(() => {
        if (props.match.params.id) {
            getApplicationDetails(props.match.params.id)
        } else {
            setLoader(false)
            setIsError(true)
            //history.push('/user-application-list')
            // window.open('/user-application-list', '_self')
        }
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">User Details</h4>
                                                </div>
                                            </div>
                                            <div className="nk-block-head nk-block-head-lg">
                                                {
                                                    applicationData?.role === 'user' ?
                                                        <div className="nk-block-head-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                            <button className="btn btn-md btn-primary" onClick={(e) => {
                                                                setOpen(open ? false : true)
                                                            }}>Make Admin</button>
                                                        </div>
                                                        : applicationData?.role === 'admin' || applicationData?.role === 'superadmin' ?
                                                            <div className="nk-block-head-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                                <button className="btn btn-md btn-primary" onClick={(e) => {
                                                                    handleRemoveAdmin(props.match.params.id);
                                                                }} style={{margin:'5px'}}>Remove Admin</button>
                                                                <button className="btn btn-md btn-primary" onClick={(e) => {
                                                                      setOpen(open ? false : true)
                                                                      setHandlePassword(true)
                                                                }} style={{margin:'5px'}}>Change Password</button>
                                                            </div>
                                                            : null
                                                }
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                {
                                                                    isError ?
                                                                        <NotFound />
                                                                        :
                                                                        <div className="nk-tb-list nk-tb-ulist" >
                                                                            <div className="nk-block">
                                                                                <div className="nk-data data-list">
                                                                                    {
                                                                                        Object.keys(applicationData)?.map((items, index) =>
                                                                                            <div className="data-item" key={index}>
                                                                                                <div className="data-col">
                                                                                                    <div className="form-control-wrap" style={{ width: '50%', margin: '5px' }}>
                                                                                                        <input type="text" value={items} disabled name={items} className="form-control form-control-lg" style={{ border: 'none', width: '100%', textAlign: 'left', background: 'transparent', outline: 'none', boxShadow: 'none', cursor: 'text', textTransform: 'capitalize' }} />
                                                                                                    </div>
                                                                                                    <div className="form-control-wrap" style={{ width: '100%', margin: '5px' }}>
                                                                                                        {
                                                                                                            items === 'facebook' || items === 'linkedin' || items === 'instagram' ?
                                                                                                                <a href={Object.values(applicationData)[index] === true ? 'Yes' : Object.values(applicationData)[index] === false ? 'No' : Object.values(applicationData)[index]}>{Object.values(applicationData)[index] === true ? 'Yes' : Object.values(applicationData)[index] === false ? 'No' : Object.values(applicationData)[index]}</a>
                                                                                                                : items === 'domain' ?
                                                                                                                    <input type="text" value={Object.values(applicationData)[index] === 'linkedin.com' ? 'Linkedin' : Object.values(applicationData)[index] === 'facebook.com' ? 'Facebook' : Object.values(applicationData)[index] === 'google.com' ? 'Google' : Object.values(applicationData)[index]} disabled name={items} className="form-control form-control-lg" style={{ border: 'none', width: '100%', textAlign: 'left', background: 'transparent', outline: 'none', boxShadow: 'none', cursor: 'text' }} />
                                                                                                                    : items === 'applicationSubmittedDate' || items === 'createdAt' ?
                                                                                                                        <input type="text" value={dateFormatConverter(Object.values(applicationData)[index])} disabled name={items} className="form-control form-control-lg" style={{ border: 'none', width: '100%', textAlign: 'left', background: 'transparent', outline: 'none', boxShadow: 'none', cursor: 'text' }} />
                                                                                                                        :
                                                                                                                        <input type="text" value={Object.values(applicationData)[index] === true ? 'Yes' : Object.values(applicationData)[index] === false ? 'No' : Object.values(applicationData)[index]} disabled name={items} className="form-control form-control-lg" style={{ border: 'none', width: '100%', textAlign: 'left', background: 'transparent', outline: 'none', boxShadow: 'none', cursor: 'text' }} />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {/* <div className="data-item">
                                                                                        <div className="data-col">
                                                                                            <span className="data-label">Publish Date*</span>
                                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                                <input type="text" name='publishDate' placeholder='Publish Date' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>

                                                                                {/* <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{handleUpdatePassword?'Update Password':'Create Admin'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact">
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">New Password</div>
                                                <input type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='Password must be greater than 6 characters'  className="form-control form-control-lg"></input>
                                            </div>
                                        </li>
                                        <li className="data-item">
                                            <div className="data-col">
                                                <div className="data-label">Confirm Password</div>
                                                <input type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder='Password must be greater than 6 characters'  className="form-control form-control-lg"></input>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={(e) => {handleUpdatePassword?handleUpdatePassowrdAdmin(e):handleCreateAdmin(e) }}
                                            >
                                                {
                                                    handleUpdatePassword?'Update Password':'Create Admin'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default UserDetails;