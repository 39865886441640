import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-copyright"> &copy; 2022 Blaq Ventures</div>
                    <div className="nk-footer-links">
                        <ul className="nav nav-sm">
                            <li className="nav-item"><Link className="nav-link" to="/privacy-policy">Privacy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;