import React, { useEffect, useState } from 'react';
import { getMethod } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { Toaster } from 'react-hot-toast';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import NotFound from '../../PanelComponent/NotFound'
import { useHistory } from 'react-router-dom';
const ViewForm = (props) => {
    const history = useHistory()
    const [loader, setLoader] = useState(true);
    const [applicationData, setApplication] = useState([]);
    const [isError, setIsError] = useState(false);
    const getApplicationDetails = async (id) => {
        const response = await getMethod('mentor/' + id);
        if (response.success) {
            setApplication(response.data)
            setLoader(false)
        } else {
            setApplication([]);
            setLoader(false)
            setIsError(true)
        }
    }
    useEffect(() => {
        if (props.match.params.id) {
            getApplicationDetails(props.match.params.id)
        } else {
            setLoader(false)
            setIsError(true)
            //history.push('/user-application-list')
            // window.open('/user-application-list', '_self')
        }
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">View Mentor Application</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                {
                                                                    isError ?
                                                                        <NotFound />
                                                                        :
                                                                        <div className="nk-tb-list nk-tb-ulist" >
                                                                            <div className='row' style={{ padding: '15px', width: '100%', margin: '0px' }}>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>1). First Name</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {applicationData?.firstName}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>2). Last Name</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {applicationData?.lastName}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>3). Email</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {applicationData?.email} &nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>4). What are your core areas of education (list up to 3)?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.education)?.join(', ')}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>5). What are your core areas of experience (list up to 3)?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.experience)?.join(', ')}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>6). In which region are you living?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.region)}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>7). Are you interested in mentoring remotely a tech startup in africa?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.remoteMentor)}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>8). Which area of africa would be under scope for you?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.africanArea)}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>9). Which languages are you fluent with?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.languages)?.join(', ')}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>10). In which slots would you be comfortable to dedicate your time for mentoring a startup?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.timeSlots)?.join(', ')}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-12 col-12' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                                    <h3 style={{ fontSize: '16px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '600' }}>11). How would you like to be recognized?</h3>
                                                                                    <p style={{ fontSize: '14px', width: '100%', lineHeight: 'normal', textAlign: 'left', fontWeight: '550' }}>Ans: {(applicationData?.recogniseMethod)?.join(', ')}&nbsp;
                                                                                        {

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default ViewForm;