import React from 'react';
import Axios from 'axios';
import { getCookies } from './cookies';
const BASE_IMG_URL = process.env.REACT_APP_IMG_URL;
const BASE_IMG_URL2= process.env.REACT_APP_IMG_URL;
const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_URL,
    timeout: 30000,
    withCredentials: true,
    headers: {},
});
  const setAuthorizationToken = (token) => {
    if(getCookies('_admin_token')){
      AxiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : `Bearer ${getCookies('_admin_token')}`
    }else{
      AxiosInstance.defaults.headers.common.Authorization = ""
    }
  };
  const postMethod = async (endpoint, data) => {
    return new Promise(resolve => {
      var config = {
        method: 'post',
        url: endpoint,
        data: data
      };
      AxiosInstance(config).then(response => {
        resolve(response.data);
      }, error => {
        resolve(error.response.data);
      })
    });
  }
  async function putMethod(endpoint, data) {
    return new Promise(resolve => {
      var config = {
        method: 'put',
        url: endpoint,
        data: data,
      };
      //console.log('Data L ' + JSON.stringify(config))
      AxiosInstance(config).then(response => {
        resolve(response.data)
      }, error => {
        resolve(error.response.data)
      })
    })
  }

  const getMethod = async (path, params = {}) => {
    return new Promise(resolve => {
      var config = {
        method: 'get',
        url: path,
        params: params
      };
      AxiosInstance(config).then(response => {
        resolve(response.data)
      }, error => {
        if (!error.response || error.code === 'ECONNABORTED') {
          resolve({ status: false, message: 'error!' })
        } else {
          resolve(error.response.data)
        }
      })
    })
  }

  const getMethodFile = async (path, params = {}) => {
    return new Promise(resolve => {
      var config = {
        method: 'get',
        url: path,
        params: params
      };
      AxiosInstance(config).then(response => {
        resolve(response)
      }, error => {
        if (!error.response || error.code === 'ECONNABORTED') {
          resolve({ status: false, message: 'error!' })
        } else {
          resolve(error.response.data)
        }
      })
    })
  }

  async function deleteMethod(path) {
    return new Promise(resolve => {
      var config = {
        method: 'delete',
        url: path,
      };
      AxiosInstance(config).then(response => {
        resolve(response.data)
      }, error => {
        resolve(error.response.data)
      })
    })
  }
export {BASE_IMG_URL, BASE_IMG_URL2, getMethodFile , setAuthorizationToken, postMethod, putMethod, getMethod, deleteMethod};
