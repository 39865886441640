import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getMethod } from "../../Utils/Service";
import { isAuth } from "../../middleware/isAuth";
import { setCookies, getCookies } from "../../Utils/cookies";
const Topbar = (props) => {
    const history = useHistory();
    const [profileDetails, setProfileDetails] = useState({firstName:'B',lastName:'V',email:''});
    useEffect(()=>{
       isAuth().then(response=>{
           if(response.success){
            setProfileDetails(response.data)
           }
       })
    },[])
    return (
        <div className="sideBarUL nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ml-n1">
                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <Link to="/user-application-list" className="logo-link">
                            <img className="logo-light logo-img" src={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} srcSet={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} alt="logo" />
                            <img className="logo-dark logo-img" src={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} srcSet={"https://blaq.ventures/wp-content/uploads/2022/01/BlaqVentures-1.png"} alt="logo-dark" />
                        </Link>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="dropdown user-dropdown">
                                <a href="#" style={{textDecoration:'none'}} className="dropdown-toggle" data-toggle="dropdown">
                                    <div className="user-toggle">
                                        <div className="user-avatar sm">
                                            <span style={{textTransform:'uppercase'}}>{profileDetails?.firstName && profileDetails?.lastName?profileDetails?.firstName[0]+profileDetails?.lastName[0]:''}</span>
                                        </div>
                                        <div className="user-info d-none d-md-block">
                                            {/* <div className="user-status" style={{textTransform:'capitalize'}}>{profileDetails?.role?profileDetails?.role:''}</div> */}
                                            <div className="user-name dropdown-indicator">{profileDetails?.firstName && profileDetails?.lastName ?profileDetails?.firstName+' '+profileDetails?.lastName: ''}</div>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                        <div className="user-card" style={{width:'100%'}}>
                                            <div className="user-info" style={{width:'100%'}}>
                                                <span className="lead-text">{profileDetails?.firstName && profileDetails?.lastName?profileDetails?.firstName+' '+profileDetails?.lastName:''}</span>
                                                <span className="sub-text" style={{fontSize:'13px',fontWeight:'600',color:'#000'}}>{profileDetails?.email?profileDetails?.email:''}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><Link to={"/admin/"+profileDetails?.id}><em className="icon ni ni-setting-alt"></em><span>Account Setting</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><a style={{cursor:'pointer'}} onClick={async()=>{
                                               await getMethod('admin/logout').then(response=>{
                                                   setCookies('isActive', JSON.stringify({isActive:false, role:false}))
                                                   window.open('/', '_self')
                                               })
                                            }}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Topbar;